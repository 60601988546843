@import "abstracts/_abstracts";
.HomeSlider {
	position: relative;
	margin: 0 auto;
	width: 100%;

	&-swiper {
		position: relative;
		overflow: hidden;
	}

	&-wrapper {
		position: relative;
		width: 100%;
		height: 100%;
		display: flex;
		transition-property: transform;
		box-sizing: content-box;
		transform: translate3d(0,0,0);
	}

	&-controls {
		position: absolute;
		left: var(--sizeContentOffset);
		bottom: 1rem;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1rem;

		@include media(">lg") {
			left: calc((min(1920px, 100vw) - var(--sizeContentWidthMedium)) / 2 - var(--sizeContentOffset))
		}
	}

	&-pagination {
		display: flex;
		align-items: center;
		justify-content: center;
		min-width: 3rem;

		@include media('<=sm') {
			color: var(--colorThemeAccent);
		}

		.swiper-pagination-current {
			font-weight: 700;
		}
	}

	&-button {
		width: 1.5rem;
		height: 1.5rem;
		cursor: pointer;
		color: var(--colorBrand);
		transition: var(--animationBase);

		@include on-event {
			color: var(--colorOutlines);
		}

		&-prev svg {
			transform: rotate(180deg);
		}
	}
}